import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const Partners = () => {
  const data = useStaticQuery(graphql`
    query {
      allFile(filter: { relativeDirectory: { eq: "logos" } }) {
        edges {
          node {
            id
            publicURL
          }
        }
      }
    }
  `)

  return (
    <div className="py-5">
      <h4>Una escuela global y conectada</h4>
      <div className="row align-items-center justify-content-center">
        {data.allFile.edges.map(image => (
          <div
            className="col-6 col-lg-3 col-md-4 col-sm-6 d-flex"
            key={image.node.id}
          >
            <img
              className="prefooter-logos"
              style={{ objectFit: "cover" }}
              src={image.node.publicURL}
            />
          </div>
        ))}
      </div>
    </div>
  )
}

export default Partners
