import React from 'react'
import PropTypes from 'prop-types'
import ReactPlayer from 'react-player'

export const VideoComponent = ({ videoComponent }) => {
  return (
    <div className="my-5 container">
      {videoComponent.title && (
        <h3 className="title-secondary text-uppercase font-weight-extra-bold ucc-text-blue">
          {videoComponent.title}
        </h3>
      )}
      {videoComponent.description && (
        <p className="title-secondary font-weight-extra-bold ucc-text-blue">
          {videoComponent.description}
        </p>
      )}
      <div className="embed-responsive embed-responsive-16by9 mb-5 container">
        {videoComponent.videoId ? (
          <ReactPlayer
            className="embed-responsive-item"
            url={`https://www.youtube.com/embed/${videoComponent.videoId}?rel=0`}
            controls={true}
            width="100%"
            height="100%"
          />
        ) : videoComponent.mediaVideo.url && (
          <ReactPlayer
            className="embed-responsive-item"
            url={`${videoComponent.mediaVideo.url}`}  // url={`http://localhost:1337${videoComponent.mediaVideo.url}`}
            controls={true}
            width="100%"
            height="100%"
          />
        )}
      </div>
    </div>
  )
}

VideoComponent.propTypes = {
  videoComponent: PropTypes.object.isRequired
}