import React from "react"
import { Col, Row } from "react-bootstrap"
import ReactMarkdown from "react-markdown"

const CourseModality = ({ data }) => (
  <Row>
    {data
      ? data.map(lists => (
          <>
            <div
              className={`${
                data.length > 1 ? "col" : "col-12 col-md-6"
              } mx-auto`}
            >
              <div className="d-flex align-items-center justify-content-center text-dark h-100 p-2">
                <div className="flex-grow-1 bg-white p-4 rounded text-center h-100">
                  <h5 className="font-weight-bold">{lists.title}</h5>
                  <ReactMarkdown className="mb-0 format-markdown">
                    {lists.summary}
                  </ReactMarkdown>
                </div>
              </div>
            </div>
          </>
        ))
      : null}
  </Row>
)

export default CourseModality
