import React from "react"
import { Link } from 'gatsby'
import { Col, Row } from "react-bootstrap"
const data = [
  { años: 16, text: "Años promedio de experiencia docente" },
  { años: 9, text: "Años promedio de experiencia directiva" },
  { años: 13, text: "Años promedio de experiencia como consultores" },
]

const Faculty = ({slug}) => {
  return(
  <div className="faculty">
    <div className="container pt-5 pb-5">
      <div className="d-flex flex-column justify-content-center align-items-center text-center mb-3 faculty-title">
        <h4 className="title">Nuestro Faculty:</h4>
        <p className="title">
          Profesores que hacen lo que enseñan, y enseñan lo que hacen.
        </p>
        <Link to={`/profesionales/${slug}`}    className="btn btn-light mx-auto">
          Conocé nuestro Faculty
        </Link>
      </div>
      <Row className="d-flex justify-content-center align-contents-center">
        {data.map((item, index) => (
          <Col sm={4} key={index}>
            <div className="d-flex flex-column justify-content-center align-items-center text-center faculty-data">
              <div>
                <span>+</span>
                <span>{item.años}</span>
              </div>
              <div>
                <p>{item.text}</p>
              </div>
            </div>
          </Col>
        ))}
      </Row>
    </div>
  </div>
)}

export default Faculty
