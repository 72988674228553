import React from "react"
import { Accordion, Card, Col } from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import ReactMarkdown from "react-markdown"
import { faChevronDown } from "@fortawesome/free-solid-svg-icons"

const Program = ({ data }) => {
  return (
    <>
      <Col xs={12} md={6}>
        {data
          ? data.map(
              (accordions, index) =>
                index % 2 === 0 && (
                  <Accordion className="custom-accordion">
                    <Card className="bg-transparent border-0 text-white cursor-pointer">
                      <Accordion.Toggle
                        as={Card.Header}
                        variant="link"
                        eventKey="0"
                        className="bg-transparent border-bottoms"
                      >
                        <div className="d-flex">
                          <div className="flex-grow-1 accordion-title">
                            {accordions.title}
                          </div>
                          <div className="mr-2">
                            <FontAwesomeIcon icon={faChevronDown} />
                          </div>
                        </div>
                      </Accordion.Toggle>
                      <Accordion.Collapse eventKey="0">
                        <Card.Body>
                          <ReactMarkdown
                            source={accordions.summary}
                            className="format-markdown"
                          />
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  </Accordion>
                )
            )
          : null}
      </Col>
      <Col xs={12} md={6}>
        {data
          ? data.map(
              (accordions, index) =>
                index % 2 === 1 && (
                  <Accordion className="custom-accordion">
                    <Card className="bg-transparent border-0 text-white cursor-pointer">
                      <Accordion.Toggle
                        as={Card.Header}
                        variant="link"
                        eventKey="0"
                        className="bg-transparent border-bottoms"
                      >
                        <div className="d-flex">
                          <div className="flex-grow-1 accordion-title">
                            {accordions.title}
                          </div>
                          <div className="mr-2">
                            <FontAwesomeIcon icon={faChevronDown} />
                          </div>
                        </div>
                      </Accordion.Toggle>
                      <Accordion.Collapse eventKey="0">
                        <Card.Body>
                          <ReactMarkdown
                            source={accordions.summary}
                            className="format-markdown"
                          />
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  </Accordion>
                )
            )
          : null}
      </Col>
    </>
  )
}

export default Program
