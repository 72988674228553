import { Link } from "gatsby"
import React from "react"
import urlSlug from 'url-slug'

const CoverPage = ({ career, images }) => (
  <div
    className="w-100 d-flex bg-light academic-img"
    style={{
      backgroundImage: `url(${images.url})`,
    }}
  >
    <div className="w-100 flex-grow-1 d-flex flex-column align-items-center justify-content-center text-center text-white p-5 background">
      <h5 className="font-weight-lighter mb-4">{career.main.slogan}</h5>
      <h2 className="mb-4 fw-bold text-uppercase title">{career.main.title}</h2>
      <div className="btn-cont d-flex align-items-center justify-content-center flex-wrap">
        <Link to="#form" className="btn btn-outline-light m-2">
          SOLICITÁ MÁS INFO
        </Link>
        {(career.admission)? (
          <Link to={'/admision/' + urlSlug(career.career_category.name) + '/' + urlSlug(career.slug)} className="btn btn-outline-light m-2">
            INICIÁ TU ADMISIÓN
          </Link>
        ): null}
        {(career.calendlyLink)? (
          <Link to={career.calendlyLink} className="btn btn-outline-light m-2" target="_blank">
            CONVERSÁ CON NOSOTROS
          </Link>
        ): null}
        <Link to="../../preguntas-frecuentes" className="btn btn-outline-light m-2">
          PREGUNTAS FRECUENTES
        </Link>
      </div>
    </div>
  </div>
)

export default CoverPage
