import React, { useState, useLayoutEffect } from "react"

const CareerTimer = ({ timer }) => {
  const [objCountDown, setObjCountDown] = useState({})
  const getReaimTime = deadline => {
    let now = new Date(),
      remainTime = (new Date(deadline) - now + 1000) / 1000,
      remainSeconds = ("0" + Math.floor(remainTime % 60)).slice(-2),
      remainMinutes = ("0" + Math.floor((remainTime / 60) % 60)).slice(-2),
      remainHours = ("0" + Math.floor((remainTime / 3600) % 24)).slice(-2),
      remainDays = Math.floor(remainTime / (3600 * 24))
    return {
      remainTime,
      remainSeconds,
      remainMinutes,
      remainHours,
      remainDays,
    }
  }

  const countdown = (deadline, objCountDown) => {
    const timerUpdate = setInterval(() => {
      let t = getReaimTime(deadline)
      setObjCountDown(t)
      if (t.remainTime <= 1) {
        clearInterval(timerUpdate)
      }
    }, 1000)
  }

  useLayoutEffect(() => {
    countdown(timer.timerCareer[0].date + " 23:59:59 GMT-0300")
  }, [])
  if (
    new Date(timer.timerCareer[0].date + " 23:59:59 GMT-0300") > new Date() &&
    timer.visible &&
    objCountDown.remainSeconds
  ) {
    return (
      <div className="mb-5">
        <h1 className="text-center mb-2 text-sec title">{timer.title}</h1>

        {timer.timerCareer[0].visible && (
          <>
            <h6 className="text-center mb-4">
              {timer.timerCareer[0].description}
            </h6>
            <div className="d-flex align-items-center justify-content-center flex-wrap">
              <div className="number d-flex flex-column justify-flex-end text-right">
                <h1 className="display-career-timer font-weight-bold career-time-minwidth">
                  {objCountDown.remainDays}
                </h1>
                <h6 className="pr-2">
                  {objCountDown.remainDays == 1 ? "Día" : "Días"}
                </h6>
              </div>
              <h4 className="px-3">/</h4>
              <div className="number d-flex flex-column justify-content-center text-center">
                <h1 className="display-career-timer font-weight-bold career-time-minwidth">
                  {objCountDown.remainHours}
                </h1>
                <h6>Horas</h6>
              </div>
              <h4 className="px-3">/</h4>
              <div className="number d-flex flex-column justify-content-center text-center">
                <h1 className="display-career-timer font-weight-bold career-time-minwidth">
                  {objCountDown.remainMinutes}
                </h1>
                <h6>Minutos</h6>
              </div>
              <h4 className="px-3">/</h4>
              <div className="number d-flex flex-column justify-content-center text-center">
                <h1 className="display-career-timer font-weight-bold career-time-minwidth">
                  {objCountDown.remainSeconds}
                </h1>
                <h6>Segundos</h6>
              </div>
            </div>
          </>
        )}
      </div>
    )
  } else {
    return null
  }
}

export default CareerTimer
